import React from "react"
import Image from 'next/image'

export default function FAQs() {
    const currentYear = (new Date).getFullYear();
    const taxYear = currentYear - 1;
    return (
        <>
            <div className="faqs-container">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="faq">
                                <Image src="/images/icon5.png" width="80" height="73"
                                          alt="icon-1"/>
                                <h2>What's the Benefit of a Tax Extension?</h2>
                                <p>Preparing all the required documentation for your 
                                    taxes can be time consuming. If you don't get it right, 
                                    you could miss important details that cause you to refile 
                                    later, or potentially, overpay taxes due, and/or limit the 
                                    tax refund you may be entitled to! Also, it turns out you 
                                    owe money and don't pay by the deadline, nor submit a tax 
                                    extension, you could be subject to the expensive failure 
                                    to file IRS penalty.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="faq-1">
                                <Image src="/images/icon6.png" alt="icon-2" width="74"
                                           height="73"/>
                                <h2>Did You Know You Can Have An Extra 5 Months?</h2>
                                <p>A Tax Extension will give you 5 More Months to File! 
                                    When you extend your Federal Tax Return, you will 
                                    have until October 15th, {` ${currentYear} `} 
                                    to complete your return! Do you think you could 
                                    benefit from that extra time?
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
